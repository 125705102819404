import axios from "axios"

export const downloadCsv = async (data) => {
  try {
    let response = {}
    let csvRows = [[]]
    if (data) {
      response = data
    } else {
      response = await axios.get(
        `https://admin.lotusbook247.games/api/agency/MOGAMBOUSD/agency-mgmt/downline/csv?parent=MOGAMBOUSD&searchUser=&token=${sessionStorage.getItem(
          "accessToken"
        )}`
      )
    }
    if (data) {
      csvRows = [Object.keys(response.data[0])]
      // csvRows[0].push('\r\n')
      csvRows.push(ConvertToCSV(JSON.stringify(response.data)))
      // csvRows[0][csvRows[0].length - 1] = csvRows[0][csvRows[0].length - 1] + '\r\n'
    } else {
      csvRows = [Object.keys(response.data).join(",")]
      csvRows.push(
        Object.keys(response.data)
          .map(title => ("" + response.data[title]).replace(/"/g, '\\"'))
          .join(",")
      )
      // csvRows = csvRows.join("\n")
    }
    console.log('----------------------', csvRows)
    const blob = new Blob([csvRows], { type: "text/csv" })

    let tempLink = document.createElement("a")
    tempLink.style.visibility = "hidden"
    tempLink.setAttribute("href", window.URL.createObjectURL(blob))
    tempLink.setAttribute("download", "filename.csv")
    tempLink.click()
  } catch (e) {
    console.log(e)
  }
}

function ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '\r\n';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }
  console.log('ConvertToCSV', str)
  return str;
}