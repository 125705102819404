export const dropdownSportOptions = [
  'All',
  'Cricket',
  'Football',
  'Tennis',
  'Horse Racing',
  'Greyhound Racing'
];

export const dropdownMarketOptions = [
  ['All', ['All',
    'Match Odds',
    'Session Runs',
    'Session Runs Ex',
    'Tied Match',
    'Completed Match',
    'Win',
    'To Qualify',
    'Over/Under',
    'Tennis Fancy',
    'Super Over',
    'Outright',
    'Others']],

  ['Cricket', ['All',
    'Match Odds',
    'Session Runs',
    'Session Runs Ex',
    'Tied Match',
    'Completed Match',
    'Super Over',
    'Outright']],

  ['Football', ['All',
    'Match Odds',
    'To Qualify',
    'Over/Under',
    'Outright',
    'Others']],

  ['Tennis', ['All',
    'Match Odds',
    'Tennis Fancy',
    'Outright']],

  ['Horse Racing', ['All',
    'Win']],

  ['Greyhound Racing', ['All',
    'Win']],
];

export const sportOptionsId = [
  { title: 'All', id: -1 },
  { title: 'Cricket', id: 4 },
  { title: 'Football', id: 1 },
  { title: 'Tennis', id: 2 },
  { title: 'Horse Racing', id: 7 },
  { title: 'Greyhound Racing', id: 4339 },
  { title: 'Other', id: 0 }
];

export const marketOptionsId = [
  { title: 'All', id: "ALL" },
  { title: 'Match Odds', id: "MATCH_ODDS" },
  { title: 'Session Runs', id: "INNINGS_RUNS" },
  { title: 'Session Runs Ex', id: "INNINGS_RUNS_EX" },
  { title: 'Tied Match', id: "TIED_MATCH" },
  { title: 'Completed Match', id: "COMPLETED_MATCH" },
  { title: 'Win', id: "WIN" },
  { title: 'To Qualify', id: "TO_QUALIFY" },
  { title: 'Over/Under', id: "OVER_UNDER" },
  { title: 'Tennis Fancy', id: "TENNIS_FANCY" },
  { title: 'Super Over', id: "SUPER_OVER" },
  { title: 'Outright', id: "WINNER" },
  { title: 'Others', id: "OTHERS" }
];

export const defaultFilterValues = {
  sportDropdown: "All",
  marketTypeDropdown: "All",
  eventInput: "",
  stakeFrom: "",
  stakeTo: "",
  nameInput: ""
}