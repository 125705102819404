import ButtonCustom from "../../components/ButtonCustom"
import "./index.scss"
import Filters from "./filters"
import { defaultFilterValues, sportOptionsId, marketOptionsId } from "./betTickerData"
import { useEffect, useState } from "react"
import { useAuth } from "../../Providers/AuthProvider"
import { useStorage } from "../../Providers/StorageProvider"
import { Table } from "react-bootstrap"
import CurrentTime from "../../components/CurrentTime"
import moment from "moment"

export default () => {
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading } = useStorage()

  const [filtersOptions, setFilterOptions] = useState(defaultFilterValues)
  const [timerValue, setTimerValue] = useState(3)
  const [resData, setResData] = useState(null)

  const getBetTickerDataReq = async () => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(
        "agency/" +
        userName +
        "/report/bet-ticker?" +
        "&marketType=" +
        marketOptionsId.filter(item => item.title === filtersOptions.marketTypeDropdown)[0].id +
        "&sportId=" +
        sportOptionsId.filter(item => item.title === filtersOptions.sportDropdown)[0].id +
        (filtersOptions.eventInput ? "&eventName=" + filtersOptions.eventInput : "") +
        (filtersOptions.userCode ? "&searchUserCode=" + filtersOptions.userCode : "") +
        (filtersOptions.stakeFrom ? "stakesFrom=" + filtersOptions.stakeFrom : "") +
        (filtersOptions.stakeTo ? "&stakesTo=" + filtersOptions.stakeTo : "")
      )
      setResData(response.data.result)
      setTimerValue(3)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getBetTickerDataReq()
  }, [filtersOptions])

  useEffect(() => {
    let timer = null
    if (!timerValue) {
      getBetTickerDataReq()
    } else {
      timer = setTimeout(() => {
        setTimerValue(timerValue - 1)
      }, 1000)
    }
    return () => clearTimeout(timer)
  }, [timerValue])

  const addCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr) < 100) {
      let res = 0
      x.length > 1 ?
        res = x1 + x2.substring(0, 3) :
        res = x1 + '.00'
      return res
    }
    return x1
  }

  return (
    <div className="bet-ticker">
      <div className="page-header">
        <div className="page-header__main">
          <h1>Bet Ticker</h1>
          <Filters
            updateOptions={newOptions => {
              console.log('sdfsdfsdfdsfsdfsdf')
              setResData(null)
              setFilterOptions(newOptions)
            }}
            options={filtersOptions}
            resetOptions={() => {
              setResData(null)
              setFilterOptions(defaultFilterValues)
            }}
          />
        </div>
        <div className="refresh">
          <div className="refresh__timer">
            <span>{timerValue}</span>
          </div>
          <ButtonCustom
            color="cyan"
            className="refresh__button"
            onClick={() => {
              setTimerValue(3)
              getBetTickerDataReq()
            }}
          >
            Refresh
          </ButtonCustom>
        </div>
      </div>
      {/* {alert.isDisplayed ? <div className="bet-ticker__alert">
                <span>{alert.message}</span>
            </div> : <></>} */}
      <div className="bet-ticker__main">
        {resData && resData.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                {["Member", "Event", "Market", "Selection"].map(title => (
                  <th key={Math.random()} scope="col" className="text-align-left">
                    {title}
                  </th>
                ))}
                {[
                  "Odd req.",
                  "Ave. matched",
                  "Matched",
                  "Unmatched",
                  "Profit/Liability",
                  "IP address",
                  "Last Updated"
                ].map(title => (
                  <th key={Math.random()} scope="col">
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resData.map(item => (
                <tr key={item.bet_num}>
                  <td className="text-align-left">{item.memberName}</td>
                  <td className="text-align-left">
                    {item.eventName.replace(
                      "${HH:mm}",
                      moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))}
                  </td>
                  <td className="text-align-left">{item.marketName}</td>
                  <td
                    style={{
                      backgroundColor: item.side === 1 ? "#fac9d4" : "#A6D8FF",
                      color: "black"
                    }}
                    className="text-align-left"
                  >
                    {item.selectionName}
                  </td>
                  <td>{addCommas(item.oddsRequested)}</td>
                  <td>{addCommas(item.averageOdds)}</td>
                  <td>{addCommas(item.sizeMatched)}</td>
                  <td>{addCommas(item.sizeRemaining)}</td>
                  <td className={item.profitLiability >= 0 ? "-positive" : "-negative"}>
                    {addCommas(Math.ceil(item.profitLiability))}
                  </td>
                  <td></td>
                  <td>{<CurrentTime format="HH:mm:ss">{item.lastUpdatedDate}</CurrentTime>}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-align-center">
            <span>No bets matching this filter, try again</span>
          </div>
        )}
      </div>
    </div>
  )
}
