import "./index.scss"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import ModalCustom from "../ModalCustom"
import TextInputCustom from "../TextInputCustom"
import { passValidation } from "../../utils/validation"
import RadioCustom from "../RadioCustom"
import { Button, Table } from "react-bootstrap"
import ButtonCustom from "../ButtonCustom"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import AlertCustom from "../AlertCustom"
import axios from "axios"
import { numberWithCommas } from "../../utils/dataTransform"

const modalRoot = document.querySelector("#modal-root");

export default ({ settings, closeFunc }) => {
  const history = useHistory()
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = useState(null);
  const [remark, setRemark] = useState();
  const { requestsToApi } = useAuth();
  const [isSumbitting,setSubmitting] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  })

const validateInput = (input, value) => {
  if (input === "amount") {
    setAmount(value)
    if (value > (settings.action === "deposit" ?  settings.data.parent.balance : settings.data.child.balance)) {
      return setAmountError("Should be less or equal to your balance")
    } else {
      setAmountError("")
    }
    if (!value) {
      return  setAmountError("This field is required")
    } else {
      return  setAmountError("")
    }
  }
};

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
   await validateInput("amount", amount)
    if (amount > 0 && !amountError) {
      try {
        // const response = await axios.post( `http://dadm.gemexch.bet/api/agency/${settings.master}/agency-mgmt/downline/actions`,
        const response = await requestsToApi.post( `/agency/${settings.master}/agency-mgmt/downline/actions`,
          {
            "action": settings.action,
            "user":  settings.user.user.name,
            "amount": amount,
          }
        )
        setAlertOptions({ message: `Deposit was updated successfully.`, type: "green" });
        closeFunc(true);
        // setModalDepositSettings({action: 'deposit', isVisible: true, user: item, data: response.data.result });
      } catch (error) {
        setSubmitting(false);
        setAlertOptions({
          message: "Something went wrong",
            type: "red"
        })
        setSubmitting(false);
        console.log("ERRRROOOORRRR", error)
      }
      // history.push("/agency-management/downline")
    }
  };

  useEffect(()=>{
    // setAlertOptions({ message: `Deposit was updated successfully.`, type: "green" });
  },[])

  return (
    <ModalCustom visible className="modal-child-info" closeFunc={() => closeFunc()} width={"500px"}>
      <form method={'POST'} onSubmit={onSubmit}>
      <div className="deposit-pop-up">
        <span className="deposit-pop-up-title">{settings.action === "deposit" ? "Deposit" : "Withdrawal" }</span>

        <div className="deposit-pop-up-info">
          <div className="deposit-pop-up-info-title">{settings.data.parent.loginName}</div>
          <div className="deposit-pop-up-info-row">
            <div className="deposit-pop-up-info-row-item">{numberWithCommas(settings.data.parent.balance)}</div>
            <div className="deposit-pop-up-info-row-item">{amount && numberWithCommas(settings.data.parent.balance - (settings.action === "deposit" ? +amount:-amount))}</div>
          </div>
        </div>
        <div className="deposit-pop-up-info">
          <div className="deposit-pop-up-info-title">{settings.data.child.loginName}</div>
          <div className="deposit-pop-up-info-row">
            <div className="deposit-pop-up-info-row-item">{numberWithCommas(settings.data.child.balance)}</div>
            <div className="deposit-pop-up-info-row-item">{amount &&  numberWithCommas(settings.data.child.balance + (settings.action === "deposit" ? +amount:-amount)) }</div>
          </div>
        </div>
        <div className="deposit-pop-up-info">
          <div className="deposit-pop-up-info-title">Amount</div>
          <input type='number'
                 onChange={(e) => validateInput("amount" , e.target.value)}
                 value={amount}
                 className={amountError ? "error" : ""}
          ></input>
          {amountError && <div className="error-message">{amountError}</div>}
        </div>
        <div className="deposit-pop-up-info">
        <div className="deposit-pop-up-info-title">Remark</div>
        {/* <input type={text}></input> */}
        <textarea onChange={(e) => setRemark(e.target.value)}></textarea>
        </div>
        <div className="deposit-pop-up-buttonsRow">
          <div className="deposit-pop-up-buttonsRow-back" onClick={() => closeFunc()}>Back</div>
          <button disabled={isSumbitting} className="deposit-pop-up-button">
            Submit
          </button>
        </div>

        <AlertCustom timeout={3000} disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
          {alertOptions.message}
        </AlertCustom>
      </div>
      </form>
  </ModalCustom>
  )
}
