import './index.scss'
import { Table } from 'react-bootstrap'
import { useEffect, useState, useMemo } from 'react'
import { numberWithCommas } from '../../../utils/dataTransform'
import { useAuth } from '../../../Providers/AuthProvider'
import StringToRecolorNumber from '../../../components/StringToRecolorNumber'
import { useStorage } from '../../../Providers/StorageProvider'
import { createApi } from "../../../utils/requestToApi"
import DatePicker from '../../../components/DatePicker'
import moment from "moment"
// import  DateTimeRangePicker  from 'react-advanced-datetimerange-picker/dist/DateTimeRangePicker'
// import DateTimeRangeContainer from 'react-advanced-datetimerange-picker/dist/DateTimeRangeContainer'





export default ({ userId, userLevel }) => {
  const { requestsToApi } = useAuth();
  const [responseData, setResponseData] = useState(null);
  const { userName,setIsLoading } = useStorage();
  // const [token, setToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOiI2M2RjZmI5NTE2ZjM3NTEwNWY3ZjM3ZDg4YzM5NjAwYzlkNWFiMjNiY2QzNDMyZjQwNTljYjRkYzc5ZTNlZWI2IiwiYWNjZXNzUmlnaHRzIjp7Ik1BTkFHRV9ET1dOTElORSI6IkZVTExfQUNDRVNTIiwiTUFOQUdFX1JJU0siOiJGVUxMX0FDQ0VTUyIsIlBUX1RPR0dMRSI6IkZVTExfQUNDRVNTIiwiUkVQT1JUUyI6IkZVTExfQUNDRVNTIiwiU1RBVEVNRU5UUyI6IkZVTExfQUNDRVNTIiwiVFJBTlNGRVIiOiJGVUxMX0FDQ0VTUyJ9LCJhbGxvd1NoYWt0aVBybyI6ZmFsc2UsImV4cCI6MTY2MjIxNDY4NiwiaWF0IjoxNjYyMjA3NDg2LCJpc01hc3RlciI6ZmFsc2UsImxhc3RBY2Nlc3NSaWdodHNVcGRhdGVEYXRlIjpudWxsLCJsYXN0TG9naW5UaW1lIjoxNjYyMjA3NDg2NTgyLCJsZXZlbCI6IlNNQSIsImxvZ2luQXBwIjoiQWdlbmN5IiwibG9naW5Db3VudHJ5IjoiSU4iLCJsb2dpbklQIjoiMi40OS43My40NyIsImxvZ2luTmFtZSI6ImIwMi51bmljb25iZXRzdWEiLCJtYXN0ZXIiOiJaMyIsIm1hc3RlcklkIjoxMTcsIm5iZiI6MTY2MjIwNzQ4Niwic3RhdHVzIjoiQUNUSVZFIiwidGhlbWUiOiJsb3R1cyIsImFyZVJ1bGVzQXBwcm92ZWQiOm51bGwsInJvbGUiOm51bGwsImhvc3QiOiJhZG1pbi5hdXJhMjQ3LmNvbSxhZG1pbi51bmljb25iZXQuY29tLHd3dy51bmljb25iZXQuY29tIiwidG5jQWNjZXB0ZWQiOnRydWUsInVzZXIiOiJiMDIudW5pY29uYmV0c3VhIiwidXNlcklkIjo2MjM5NTYsImN1cnJlbmN5IjpudWxsfQ.aweFSfxxGKWnfaU012HTf8OAIboiVZrJVPUO0jkGOng')

  const userCountTemplate = [
    ["TOTAL_USERS", "Total Users", false],
    ["ACTIVE_USERS", "Active Users", false],
    ["CLOSED_USERS", "Closed Users", false],
    ["INACTIVE_USERS", "Inactive Users", false],
    ["SUSPENDED_USERS", "Suspended Users", false],
    ["PLACED_SAUDA_USERS", "Placed Bet Users", false],
    ["LOGGED_IN_USERS", "Logged In Users", false],
    ["NEW_USERS", "New Users", false],
  ];

  const paymentCountTemplate = [
    ["TOTAL_DEPOSIT_AMOUNT", "Total Deposit Amount", false],
    ["TOTAL_WITHDRAW_AMOUNT", "Total Withdraw Amount", false],
    ["TOTAL_DEPOSIT_CUSTOMERS", "Total Deposit Customers", false],
    ["TOTAL_WITHDRAW_CUSTOMERS", "Total Withdraw Customers", false],
    ["COMMON_DW_CUSTOMERS", "Common DW Customers", false],
    ["COMMON_DW_CUSTOMERS_DEPOSIT_AMOUNT", "Common DW Customers Deposit Amount", false],
    ["COMMON_DW_CUSTOMERS_WITHDRAW_AMOUNT", "Common DW Customers Withdraw Amount", false],
    ["ONLY_DEPOSIT_CUSTOMERS", "Only Deposit Customers", false],
    ["ONLY_DEPOSIT_CUSTOMERS_AMOUNT", "Only Deposit Customers Amount", false],
    ["ONLY_WITHDRAW_CUSTOMERS", "Only Withdraw Customers", false],
    ["ONLY_WITHDRAW_CUSTOMERS_AMOUNT", "Only Customers Amount", false],
  ]

  const now = new Date();
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  });
  const [applyDate,setApplyDate] = useState(false);


  // const logout = () => {
  //   sessionStorage.clear()
  //   setIsAuthenticated(false)
  // }

  // const requestsToApi = useMemo(() => {
  //   return createApi(`https://admin.gemexch.bet/api`, logout, token)
  // }, [token]);

  useEffect( () => {
    getCounts()
  }, [dateTimeRange]);

  const getCounts = async() =>{
    try {
      setIsLoading(true);
      const response = await requestsToApi.get(`/agency/${userName}/account/downline-analysis?dl=${userId}${dateTimeRange.start? `&date=${new Date(dateTimeRange.start).setHours(0,0,0,0)}`:""}`);
      // console.log("fhfkj",response.data.result)
      setIsLoading(false);
      setResponseData(response.data.result)
    } catch (error) {
      setIsLoading(false)
      console.log(error?.message)
    }
  }

  const response = {
    status: {
        statusCode: '0',
        statusDesc: 'Success'
    },
    success: true,
    result: {
        success: true,
        status: {
            statusCode: '0',
            statusDesc: 'Success'
        },
        result: {
            TOTAL_USERS: 5828,
            ACTIVE_USERS: 5828,
            CLOSED_USERS: 0,
            INACTIVE_USERS: 0,
            SUSPENDED_USERS: 0,
            MEMBER_CODE: 'Z301',
            PLACED_SAUDA_USERS: 22,
            LOGGED_IN_USERS: 69,
            NEW_USERS: 25
        }
    }
}



  const formatData = (num, recolor) => {
    return recolor ?
      <StringToRecolorNumber>{num || 0}</StringToRecolorNumber> :
      <span>{num || 0}</span>
  }

  return <div className="userCount-report">

    <div className="userCount-body">
      <div className="page-header">
        <div className="page-header__main" style={{display: 'flex', marginBottom: '10px'}}>
          <div className='userCount-title'>User Count</div>
          <div>
            <div>
              <DatePicker
                dateTimeRange={dateTimeRange}
                onChangeFunc={result => {

                  setResponseData(null)
                  setDateTimeRange(result)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Table striped hover variant="dark">
          <tbody>
            {
            userCountTemplate.map(([key, title, recolor]) => (
              <tr key={title}>
                <th className="" >{title}</th>
                <td className="" >{formatData(responseData?.users[key], recolor)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Table striped hover variant="dark" className='ml-5'>
          <tbody>
            {
            paymentCountTemplate.map(([key, title, recolor]) => (
              <tr key={title}>
                <th className="" >{title}</th>
                <td className="" >{formatData(responseData?.payments[key], recolor)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  </div>
}