import "./index.scss"
import { useStorage } from "../../Providers/StorageProvider"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import DownlineNav from "../MAListingPage/Nav"
import { Navbar, Nav } from "react-bootstrap"
import ActivityReport from "./ActivityReport"
import BalanceReport from "./BalanceReport"
import BetListReport from "./BetListReport"
import TransferStatement from "../TransferStatement"
import StatementPage from "../StatementPage"
import NetExposureReport from "./NetExposureReport"
import UserCount from "./UserCount"
import RadioCustom from "../../components/RadioCustom"
import WedgerReport from "./wedgerReport"
import ExpousreDetail from "./ExpousreDetail"
import BettingPL from "./BettingPL"

const BetListReportPage = ({ match }) => {
  const { userName } = useStorage()
  const history = useHistory()
  const [userData, setUserData] = useState(null);
  const [wallets,getWallets] = useState({});
  const { requestsToApi } = useAuth();
  const [id,setId] = useState(match.params.id)
  const [walletId,setWalletId] = useState(true);
  
  useEffect(async () => {
    try {
      const response = await requestsToApi.get(
        `agency/${userName}/agency-mgmt/downline?parent=${match.params.parent}&searchUser=${match.params.id}`
      )
      setUserData(response?.data?.result?.downline[0]?.user)
    } catch (error) {
      console.log(error?.message)
    }
  }, [])
  useEffect(()=>{
    if(id === match.params.id){
      setWalletId(true);
    }
    else{
      setWalletId(false);
    }
  },[id])

  const tabAdjuster = () => {
    if (userData) {
      // const {
      //   params: { id }
      // } = match
      switch (history.location.hash) {
        case "#balance":
          return <BalanceReport userLevel={userData.level} userId={id} />
        case "#userCount":
          return <UserCount userLevel={userData.level} userId={id} />
        case "#past":
        case "#current":
          return <BetListReport userId={id} userLevel={userData.level} />
        case "#pnl-statement":
        case "#credit-statement":
        case "#market":
          return <StatementPage userId={id} userLevel={userData.level} />
        case "#betting-pnl":
          return <BettingPL userId={id} userLevel={userData.level} />
        case "#transfer-statement":
          return <TransferStatement userId={id} userData={userData} />
        case "#net-exposure":
          return <NetExposureReport userId={id} userLevel={userData.level} />
        case "#wedger":
          return <WedgerReport userId={id} userLevel={userData.level} />
        case "#exposure-details":
          return <ExpousreDetail userId={id} userLevel={userData.level} />
        default:
          return <ActivityReport userId={id} userLevel={userData.level} getWallets={getWallets} />
      }
    }
  }

  const followTheLink = href => () => {
    const rebuiltURL = history.location.pathname.split("/").slice(0, 4).join("/")
    history.push(rebuiltURL + href)
  }

  const radioToggle = e => {
    console.log(e.target.attributes["status"].value);
    setId(e.target.attributes["status"].value);
  }
  return (
    <div className="downline-report">
      <div className="downline-report__header">
        <div className="nav">
          {userData ? <DownlineNav uplineData={[{ loginName: userData.loginName, username: userData.name }]}
            url={history.location.state ? history.location.state.lastUrl : '/agency-management/downline'} /> : <></>}
            {
              wallets.b || wallets.c || wallets.s ? 
            <div className="bet-list-report__radios">
              <span className="sport-radios__label">Filter by Wallets: </span>
                <RadioCustom name="wallet" status={match.params.id} type="green" onChange={radioToggle} checked>
                  {"Main Wallet"}
                </RadioCustom>
                {
                  wallets.b?
                  <RadioCustom name="wallet" status={wallets.b.mc} type="green" onChange={radioToggle}>
                    Bonus Wallet
                  </RadioCustom>
                  :""
                }
                {
                  wallets.c?
                  <RadioCustom name="wallet" status={wallets.c.mc} type="green" onChange={radioToggle}>
                    Casino Wallet
                  </RadioCustom>
                  :""
                }
                {
                  wallets.s?
                  <RadioCustom name="wallet" status={wallets.s.mc} type="green" onChange={radioToggle}>
                    Sport Wallet
                  </RadioCustom>
                  :""
                }
              </div>
              :""
            }
        </div>
      </div>
      <div className="downline-report__delimiter"></div>
      <div className="downline-report__main">
        <Navbar className="report-tabs" variant="dark">
          <Nav
            defaultActiveKey={
              history.location.hash === ""
                ? "#home"
                : history.location.hash === "#credit-statement" || history.location.hash === "#market"
                  ? "#pnl-statement"
                  : history.location.hash === "#past"
                    ? "#current"
                    : history.location.hash
            }
            className="mr-auto"
          >
            {
              walletId && userData?.level !== "Member" ?
              <Nav.Link href="#userCount" onClick={followTheLink("#userCount")}>
                User Count
              </Nav.Link>
              :""
            }
            <Nav.Link href="#home" onClick={followTheLink("#home")}>
              Activity
            </Nav.Link>
            {userData && userData.level !== "Member" ? (
              <Nav.Link href="#balance" onClick={followTheLink("#balance")}>
                Balance
              </Nav.Link>
            ) : (
              <></>
            )}
            <Nav.Link href="#current" onClick={followTheLink("#current")}>
              Bet List
            </Nav.Link>
            {/*{userData && userData.level === "Member" && walletId ? (*/}
            {userData && userData.level === "Member" && walletId ? (
              <Nav.Link href="#betting-pnl" onClick={followTheLink("#betting-pnl")}>
                Betting P&L
              </Nav.Link>
            ) : (
              <></>
            )}
            <Nav.Link href="#pnl-statement" onClick={followTheLink("#pnl-statement")}>
              Account Statement
            </Nav.Link>
            {walletId ?
              (<Nav.Link href="#transfer-statement" onClick={followTheLink("#transfer-statement")}>
              Transfer Statement
            </Nav.Link>)
            :""  
          }
            <Nav.Link href="#net-exposure" onClick={followTheLink("#net-exposure")}>
              Net Exposure
            </Nav.Link>
            {
              wallets.b && id == wallets.b.mc?
              <Nav.Link href="#wedger" onClick={followTheLink("#wedger")}>
                Wedger
              </Nav.Link>
              :""
            }
            <Nav.Link href="#exposure-details" onClick={followTheLink("#exposure-details")}>
              Exposure Details
            </Nav.Link>
          </Nav>
        </Navbar>
        <div className="main-page-content">
          <div className="tab-content">{tabAdjuster()}</div>
        </div>
      </div>
    </div>
  )
}
export default BetListReportPage
